import { connect } from 'react-redux';
import EditCustomer from '../components/EditCustomer';
import {updateCustomer} from '../actions/customerActions';

const mapStateToProps = state => {
  return {
    
  } 
}

const mapDispatchToProps = dispatch => {
  return {
    saveCustomer: (customer, closure) => dispatch(updateCustomer(customer, closure))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCustomer)
