import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import DatePicker from './DatePicker';
import dateService from '../services/dateService';

class AddAppointment extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSelectCustomer = this.onSelectCustomer.bind(this);
        this.state = {
            selectedCustomer: this.props.selectedCustomer,
            dropdownTitle: this.props.selectedCustomer != null ? this.props.selectedCustomer.name : "Select Customer"
        };
    }

    onSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            this.setState({
                validated: true
            })
            return;
        }
        
        const appointment = {};
        appointment.customerId = form.elements.formGroupCustomerId.value;
        appointment.startDate = dateService.getIsoString(dateService.getDateFromString(form.elements.formGroupStartDate.value));
        appointment.endDate = dateService.getIsoString(dateService.getDateFromString(form.elements.formGroupEndDate.value));

        this.props.saveAppointment(appointment, this.props.closure);
    }

    onSelectCustomer = (key, event) => {
        var selected = this.props.customers.find(customer => customer.id === key);
        this.setState(
            {
                selectedCustomer: selected,
                dropdownTitle: selected.name
            }
        );
    }

    render() {
        var handleClose = this.props.closure;
        return (
            <Form noValidate validated={this.state.validated} onSubmit={this.onSubmit} >
                <Form.Group controlId="formGroupCustomerId">
                    <Form.Label>Customer *</Form.Label>
                    <DropdownButton title={this.state.dropdownTitle}>
                    {this.props.customers.map((customer, index) =>
                        <Dropdown.Item key={index} eventKey={customer.id} onSelect={this.onSelectCustomer}>{customer.name}</Dropdown.Item>
                    )}
                    </DropdownButton>
                    <Form.Control required readOnly type="text" hidden={true} value={this.state.selectedCustomer != null ? this.state.selectedCustomer.id : ""} />
                    <Form.Control.Feedback type="invalid">Please select customer</Form.Control.Feedback> 
                </Form.Group>
                <Form.Group controlId="formGroupStartDate">
                    <Form.Label>Start Time *</Form.Label>
                    <DatePicker customInput={<Form.Control type="text" />} startDate={this.props.start} dateFormat="yyyy-MM-dd HH:mm" />
                </Form.Group>
                <Form.Group controlId="formGroupEndDate">
                    <Form.Label>End Time *</Form.Label>
                    <DatePicker customInput={<Form.Control type="text" />} startDate={this.props.end} dateFormat="yyyy-MM-dd HH:mm"/>
                </Form.Group>
                <div className="float-right">
                    <Button className="mr-2" variant="light" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Create
                    </Button>
                </div>
            </Form>
        );
    }
}

export default AddAppointment;