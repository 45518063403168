const colors = [
    // {
    //     "name": "white",
    //     "hex": "#FFFFFF",
    //     "rgb": "rgb(255,255,255)"
    // },
    {
        "name": "snow",
        "hex": "#FFFAFA",
        "rgb": "rgb(255,250,250)"
    },
    {
        "name": "honeydew",
        "hex": "#F0FFF0",
        "rgb": "rgb(240,255,240)"
    },
    {
        "name": "mintcream",
        "hex": "#F5FFFA",
        "rgb": "rgb(245,255,250)"
    },
    {
        "name": "azure",
        "hex": "#F0FFFF",
        "rgb": "rgb(240,255,255)"
    },
    {
        "name": "aliceblue",
        "hex": "#F0F8FF",
        "rgb": "rgb(240,248,255)"
    },
    {
        "name": "ghostwhite",
        "hex": "#F8F8FF",
        "rgb": "rgb(248,248,255)"
    },
    {
        "name": "whitesmoke",
        "hex": "#F5F5F5",
        "rgb": "rgb(245,245,245)"
    },
    {
        "name": "seashell",
        "hex": "#FFF5EE",
        "rgb": "rgb(255,245,238)"
    },
    {
        "name": "beige",
        "hex": "#F5F5DC",
        "rgb": "rgb(245,245,220)"
    },
    {
        "name": "oldlace",
        "hex": "#FDF5E6",
        "rgb": "rgb(253,245,230)"
    },
    {
        "name": "floralwhite",
        "hex": "#FFFAF0",
        "rgb": "rgb(255,250,240)"
    },
    {
        "name": "ivory",
        "hex": "#FFFFF0",
        "rgb": "rgb(255,255,240)"
    },
    {
        "name": "antiquewhite",
        "hex": "#FAEBD7",
        "rgb": "rgb(250,235,215)"
    },
    {
        "name": "linen",
        "hex": "#FAF0E6",
        "rgb": "rgb(250,240,230)"
    },
    {
        "name": "lavenderblush",
        "hex": "#FFF0F5",
        "rgb": "rgb(255,240,245)"
    },
    {
        "name": "mistyrose",
        "hex": "#FFE4E1",
        "rgb": "rgb(255,228,225)"
    }
]

const colorService = {};

colorService.getColors = () => {
    return colors
}

colorService.getColor = identifier => {
    let sum = 0;
    for (var i = 0; i < identifier.length; i++) {
        sum += identifier.charCodeAt(i);
    }
    return colors[sum % colors.length]
}

export default colorService;