import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class TwoSplitPane extends React.Component {

  render() {
    return (

      <Container fluid="true">
        <Row>
          <Col sm={2}>{this.props.left}</Col>
          <Col sm={10}>{this.props.right}</Col>
        </Row>
      </Container>
    );
  }
}

export default TwoSplitPane;
