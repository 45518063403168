import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import DeleteDialogue from './DeleteDialogue'
import Modal from './MyModal'
import AddAppointmentContainer from '../containers/AddAppointmentContainer'
import dateService from '../services/dateService'

class FullCalendarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.calendarComponentRef = React.createRef();
    this.state = {
      showEventOptions: false,
      selectedEvent: {},
      currentView: "timeGridWeek"
    }
  }

  componentWillMount() {
    // this.props.loadAppointments();
  }

  getEventsFromAppointments = (appointments) => {
    return appointments.map(
      appointment => {
        return {
          id: appointment.id,
          start: appointment.startDate,
          end: appointment.endDate,
          title: appointment.customer.name || appointment.id,
          backgroundColor: appointment.customer.color
        }
      }
    )
  }

  now = () => {
    return new Date();
  }

  isMobile = () => {
    return  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) === true
  }

  render() {
    const timeFormat = {
      hour: "numeric",
      minute: "2-digit",
      omitZeroMinute: false,
      meridiem: false,
      hour12: false
    };

    return (
      <div className="mt-3" style={{ minHeight: '800px' }}>
        <FullCalendar
          defaultView= {this.isMobile() ? "timeGridDay" : "timeGridWeek"}
          customButtons={{
            copyAppointmentsToNextWeek: {
              text:"copy",
              "click": this.copyAppointmentsToNextWeek
            }
          }}
          header={{
            left: ' today prev,next',
            center: 'title',
            right: 'copyAppointmentsToNextWeek timeGridWeek,timeGridDay'
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          datesRender={this.handleDatesRender}
          ref={this.calendarComponentRef}
          weekends={true}
          dateClick={this.handleDateClick}
          select={this.handleDateClick}
          height="parent"
          weekNumberCalculation="ISO"
          minTime={{ hours: 5 }}
          maxTime={{ hours: 22 }}
          scrollTime={{ hours: 9 }}
          slotLabelFormat={timeFormat}
          // eventTimeFormat={timeFormat} for now this is not needed as displayEventTime is false (below)
          displayEventTime={false}
          nowIndicator="true"
          now={this.now}
          editable="true"
          events={this.getEventsFromAppointments(this.props.appointments)}
          eventClick={this.eventClick}
          selectable="true"
          selectMirror="true"
          eventResize={this.updateAppointment}
          eventDrop={this.updateAppointment}
        />

        <Modal
          content={<DeleteDialogue
            id={this.state.selectedEvent.id}
            close={this.hideEventOptions}
            title={"Appointment for: " + (this.state.selectedEvent.event != null ? this.state.selectedEvent.event.title : null)}
            toDelete={this.state.selectedEvent.event != null ? this.state.selectedEvent.event.id : null}
            deleteAction={this.deleteEvent} />
          }
          title="Delete appointment" show={this.state.showEventOptions} close={this.hideEventOptions}
        />

        <Modal
          content={<AddAppointmentContainer start={this.state.newAppointmentStart} end={this.state.newAppointmentEnd} closure={this.hideAddAppointment} />}
          title="Add Appointment" show={this.state.showAddAppointment} close={this.hideAddAppointment} />
      </div>)
  }

  handleDateClick = (arg) => {
    this.setState({
      showAddAppointment: true,
      newAppointmentStart: arg.start,
      newAppointmentEnd: arg.end
    })
  }

  hideAddAppointment = () => {
    this.setState({
      showAddAppointment: false
    })
  }

  eventClick = (info) => {
    this.setState({
      showEventOptions: true,
      selectedEvent: info
    })
  }

  hideEventOptions = () => {
    this.setState({
      showEventOptions: false
    })
  }

  updateAppointment = (info) => {
    if (this.state.currentView === 'dayGridMonth') {
      return;
    }

    var appointment = { ...this.props.appointments.filter(a => a.id === info.event.id)[0] };
    var updateAppointment = {};
    updateAppointment.id = appointment.id;
    updateAppointment.customerId = appointment.customerId;
    updateAppointment.startDate = dateService.getIsoString(info.event.start);
    updateAppointment.endDate = dateService.getIsoString(info.event.end);
    updateAppointment.revision = appointment.revision;

    this.props.updateAppointment(updateAppointment);
  }

  deleteEvent = (argument) => {
      this.props.deleteAppointment(argument, () => {
        this.hideEventOptions();
      })
  }

  copyAppointmentsToNextWeek = () => {
    var currentStart = this.calendarComponentRef.current.calendar.view.currentStart;
    var currentEnd = this.calendarComponentRef.current.calendar.view.currentEnd;

    var nextWeekAppointments = [];
    this.props.appointments
    .filter(apppointment => dateService.isBetween(apppointment.startDate, currentStart, currentEnd, true))
    .forEach(appointment => {
      var newAppointment = {};
      newAppointment.customerId = appointment.customerId;
      newAppointment.startDate = dateService.getIsoString(dateService.addDays(appointment.startDate, 7));
      newAppointment.endDate = dateService.getIsoString(dateService.addDays(appointment.endDate, 7));
      nextWeekAppointments.push(newAppointment);
    });
    this.props.copyAppointments(nextWeekAppointments, this.props.customers);
}

  handleDatesRender = (info) => {
    this.setState({
      currentView: info.view.type
    })
  }

}

export default FullCalendarComponent;
