import { connect } from 'react-redux';
import Customers from '../components/Customers';
import { loadSubscriptions } from '../actions/subscriptionActions';
import { loadAppointments } from '../actions/appointmentActions';
import {selectCustomer, clearSelectedCustomer, loadCustomers, filterCustomers} from '../actions/customerActions';
import dateService from '../services/dateService';


const getVisibleCustomers = (customers, customerFilterString) => {
  customers.sort((a,b) => {
    return a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase())
  })
  
  if (customerFilterString == null || customerFilterString.length < 3) {
    return customers;
  }
  return customers.filter(c => c.name.toLowerCase().indexOf(customerFilterString.toLowerCase()) > -1);
}

const mapStateToProps = state => {
  return {
    customers: getVisibleCustomers(state.customers.customers, state.customers.customerFilterString),
    selectedCustomer: state.customers.selectedCustomer,
    subscriptions: state.subscriptions.subscriptions,
    appointments: state.appointments.appointments
  } 
}

const mapDispatchToProps = dispatch => {
  return {
    selectCustomer: (customer) => dispatch(selectCustomer(customer)),
    clearSelectedCustomer: () => dispatch(clearSelectedCustomer()),
    loadCustomers: () => {
      dispatch(loadCustomers());
      dispatch(loadSubscriptions(null, dateService.subtractDays(new Date(), 30), true));
      dispatch(loadAppointments(dateService.getDateString(dateService.subtractDays(new Date(), 60))))
    },
    filterCustomers: (searchString) => dispatch(filterCustomers(searchString))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customers)
