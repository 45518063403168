import { connect } from 'react-redux';
import FullCalendarComponent from '../components/FullCalendarComponent';
import { loadAppointments, updateAppointment, deleteAppointment, copyAppointments } from '../actions/appointmentActions';
import customerService from '../services/customerService';


const mapStateToProps = state => {
  return {
    customers: state.customers.customers,
    selectedCustomer: state.customers.selectedCustomer,
    appointments: customerService.enrichWithCustomers(state.appointments.appointments, state.customers.customers)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadAppointments: () => dispatch(loadAppointments()),
    updateAppointment: (appointment) => dispatch(updateAppointment(appointment)),
    deleteAppointment: (appointment, closure) => dispatch(deleteAppointment(appointment, closure)),
    copyAppointments: (appointments, customers) => dispatch(copyAppointments(appointments, customers))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FullCalendarComponent)
