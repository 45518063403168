import moment from 'moment';

const internalRepresentation = (date) => {
    if (typeof date === 'string' || date instanceof String) {
        return moment(date)
    }
    if (date instanceof Date) {
        return moment(date);
    }
    if (date instanceof moment) {
        return date;
    }
    throw new Error("argument is not of type Date")
}

const dateService = {};

dateService.getIsoString = (date) => {
    return internalRepresentation(date).toISOString();
}

dateService.getDateString = (date) => {
    return moment.utc(date).toISOString().slice(0, 10);
}

dateService.getDateFromString = (dateString) => {
    return moment(dateString)
}

dateService.getCurrentTimeAsIsoString = () => {
    return moment().toISOString();
}

dateService.isBetween = (date, start, end, inclusive) => {
    const internalDate = internalRepresentation(date);
    const internalStart = internalRepresentation(start);
    const internalEnd = internalRepresentation(end);
    
    if (inclusive) {
        return internalStart.toISOString() <= internalDate.toISOString() && internalDate.toISOString() <= internalEnd.toISOString();        
    } else {
        return internalStart.toISOString() < internalDate.toISOString() && internalDate.toISOString() < internalEnd.toISOString();
    }
}

dateService.isBefore = (date1, date2) => {
    const internalDate1 = internalRepresentation(date1);
    const internalDate2 = internalRepresentation(date2);
    return internalDate1.toISOString() < internalDate2.toISOString();
}

dateService.subtractSeconds = (date, secondsToSubtract) => {
    return internalRepresentation(date).subtract({seconds: secondsToSubtract})
}
dateService.addSeconds = (date, secondsToAdd) => {
    return internalRepresentation(date).add({seconds: secondsToAdd})
}

dateService.subtractDays = (date, daysToSubtract) => {
    return internalRepresentation(date).subtract({days: daysToSubtract})
}
dateService.addDays = (date, daysToAdd) => {
    return internalRepresentation(date).add({days: daysToAdd})
}

export default dateService;