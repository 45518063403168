import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

class Customer extends React.Component {
  
  handleClick = (e) => {
    e.preventDefault();

    const { selectCustomer } = this.props;
    selectCustomer(this.props.customer);
  }

  render() {
    return (
      // <ListGroup.Item eventKey={this.props.customer.id} action onClick={this.handleClick}>{this.props.customer.name}</ListGroup.Item>
      <ListGroup.Item action onClick={this.handleClick} color={this.props.customer.color} className="text-left">
        <FontAwesomeIcon icon={faSquare} size="1x" color={this.props.customer.color} />
        <FontAwesomeIcon className="mr-1" icon={faSquare} size="1x" color={this.props.customer.color} />
        {this.props.customer.name}  
      </ListGroup.Item>
    );
  }
}

export default Customer;