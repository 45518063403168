import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/analytics';

//there is a sed command to replace prod keys with the dev keys. everything must stay on one line and using ' instead of "
var firebaseConfig = { apiKey: 'AIzaSyBKJFPm66R14pNdmbjRXjRtWtsQE4uvw0w', authDomain: 'scheduler-prod-64ec1.firebaseapp.com', databaseURL: 'https://scheduler-prod-64ec1.firebaseio.com', projectId: 'scheduler-prod-64ec1', storageBucket: 'scheduler-prod-64ec1.appspot.com', messagingSenderId: '1026118728635', appId: '1:1026118728635:web:86972d042999ba2bbfa29e', measurementId: 'G-81KCVMNY7K' };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//for now we initialize analytics like this
firebase.analytics();

export default firebase;