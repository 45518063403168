import { APPOINTMENT_ACTIONS, apologize } from './actions';
import schedulerApi from '../services/schedulerApi';
import { checkIfNewAppointmentIsAllowed } from '../services/validationService';
import MultiError from '../errors/MultiError'


export function loadAppointments(since) {
    return function (dispatch) {
        return schedulerApi.getAppointments(null, since, true)
            .then(appointments => {
                appointments.forEach(appointment => dispatch(appointmentAdded(appointment)));
                return Promise.resolve(appointments);
            },
                error => dispatch(apologize(error))
            );
    };
}

export function addAppointment(appointment, closure) {
    return function (dispatch) {
        return schedulerApi.addAppointment(appointment)
            .then(addedAppointment => {
                dispatch(appointmentAdded(addedAppointment));
                if (closure != null) {
                    dispatch(() => closure())
                }
            },
                error => dispatch(apologize(error))
            );
    };
}

export function updateAppointment(appointment) {
    return function (dispatch) {
        return schedulerApi.updateAppointment(appointment)
            .then(updated => {
                dispatch(appointmentUpdated(appointment));
            },
                error => dispatch(apologize(error))
            );
    };
}

export function deleteAppointment(appointment, closure) {
    return function (dispatch) {
        return schedulerApi.deleteAppointment(appointment)
            .then(deletedAppointment => {
                dispatch(appointmentDeleted(appointment));
                dispatch(() => closure())
            },
                error => dispatch(apologize(error))
            );
    };
}

export function copyAppointments(appointments, customers, closure) {
    return function (dispatch) {

        return Promise.allSettled(appointments.map(appointment => checkIfNewAppointmentIsAllowed(appointment, true)))
            .then(results => {
                var errors = results
                    .filter(result => result.status === 'rejected')
                    .map(result => result.reason);
                return Promise.allSettled(
                    results
                        .filter(result => result.status === 'fulfilled')
                        .map(result => schedulerApi.addAppointment((result.value)))
                )
                    .then(addResults => {
                        addResults
                            .filter(result => result.status === 'fulfilled')
                            .forEach(result => dispatch(appointmentAdded(result.value)));
                        errors = errors.concat(addResults.filter(result => result.status === 'rejected').map(result => result.reason));

                        if (errors.length > 0) {
                            dispatch(apologize(new MultiError(errors.map(e => {
                                if (e.source != null) {
                                    return e.message + " : " + customers.filter(c => c.id === e.source.customerId)[0].name;
                                } else {
                                    return e.message
                                }
                            }))));
                        } else if (closure != null) {
                            dispatch(() => closure())
                        }
                    })
                    .catch(error => dispatch(apologize(error)))
            })
            .catch(error => dispatch(apologize(error)))
    }
}

function appointmentAdded(addedAppointment) {
    return {
        type: APPOINTMENT_ACTIONS.APPOINTMENT_ADDED,
        addedAppointment
    }
}

function appointmentUpdated(updatedAppointment) {
    return {
        type: APPOINTMENT_ACTIONS.APPOINTMENT_UPDATED,
        updatedAppointment
    }
}

function appointmentDeleted(deletedAppointment) {
    return {
        type: APPOINTMENT_ACTIONS.APPOINTMENT_DELETED,
        deletedAppointment
    }
}