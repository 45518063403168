import { SUBSCRIPTION_ACTIONS, apologize } from './actions';
import schedulerApi from '../services/schedulerApi';


export function loadSubscriptions(customerId, date, includeAfter) {
    return function (dispatch) {
        return schedulerApi.getSubscriptions(customerId, date, includeAfter)
        .then(
            subscriptions => {
                subscriptions.forEach(subscription => dispatch(subscriptionAdded(subscription)));
                return Promise.resolve(subscriptions);
            },
            error => dispatch(apologize(error))
        );
    };
}

export function addSubscription(subscription, closure) {
    return function (dispatch) {
        return schedulerApi.addSubscription(subscription)
        .then(
            addedSubscription => {
                dispatch(subscriptionAdded(addedSubscription));
                if (closure != null) {
                    dispatch(() => closure())
                }
            },
            error => dispatch(apologize(error))
        );
    };
}

export function updateSubscription(subscription, closure) {
    return function (dispatch) {
        return schedulerApi.updateSubscription(subscription)
        .then(
            updated => {
                dispatch(subscriptionUpdated(subscription));
                if (closure != null) {
                    dispatch(() => closure())
                }
            },
            error => dispatch(apologize(error))
        );
    };
}

export function deleteSubscription(subscription, closure) {
    return function (dispatch) {
        return schedulerApi.deleteSubscription(subscription)
        .then(
            deletedSubscription => {
                dispatch(subscriptionDeleted(subscription));
                dispatch(() => closure())
            },
            error => dispatch(apologize(error))
        );
    };
}

function subscriptionAdded(addedSubscription) {
    return {
        type: SUBSCRIPTION_ACTIONS.SUBSCRIPTION_ADDED,
        addedSubscription
    }
}

function subscriptionUpdated(updatedSubscription) {
    return {
        type: SUBSCRIPTION_ACTIONS.SUBSCRIPTION_UPDATED,
        updatedSubscription
    }
}

function subscriptionDeleted(deletedSubscription) {
    return {
        type: SUBSCRIPTION_ACTIONS.SUBSCRIPTION_DELETED,
        deletedSubscription
    }
}