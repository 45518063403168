// import httpSchedulerApi from '../services/httpSchedulerApi';
import firebaseSchedulerApi from '../services/firebaseSchedulerApi';

const schedulerApi = {};

const implementation = firebaseSchedulerApi;
// const implementation = httpSchedulerApi;

schedulerApi.addCustomer = (customer) => {
    return implementation.addCustomer(customer)
}

schedulerApi.updateCustomer = (customer) => {
    return implementation.updateCustomer(customer)
}

schedulerApi.getCustomers = () => {
    return implementation.getCustomers()
}

schedulerApi.getSubscriptions = (customerId, date, includeAfter) => {
    return implementation.getSubscriptions(customerId, date, includeAfter)
}

schedulerApi.addSubscription = (subscription) => {
    return implementation.addSubscription(subscription)
}

schedulerApi.updateSubscription = (subscription) => {
    return implementation.updateSubscription(subscription)
}

schedulerApi.deleteSubscription = (subscriptionId) => {
    return implementation.deleteSubscription(subscriptionId)
}

schedulerApi.getAppointments = (customerId, date, includeAfter) => {
    return implementation.getAppointments(customerId, date, includeAfter)
}

schedulerApi.addAppointment = (appointment) => {
    return implementation.addAppointment(appointment)
}

schedulerApi.updateAppointment = (appointment) => {
    return implementation.updateAppointment(appointment)
}

schedulerApi.deleteAppointment = (appointmentId) => {
    return implementation.deleteAppointment(appointmentId)
}

schedulerApi.login = (user) => {
    return implementation.login(user)
}

schedulerApi.logout = () => {
    return implementation.logout()
}

export default schedulerApi;