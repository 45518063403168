import React from 'react';
import Button from 'react-bootstrap/Button';

class DeleteDialogue extends React.Component {

    handleDeleteAction = e => {
        e.preventDefault();
        this.props.deleteAction(this.props.toDelete);
    }

    render() {
        var handleClose = this.props.close;
        return (
            <div>
                <label>{this.props.title}</label>
                <div className="float-right">
                    <Button className="mr-2" variant="light" onClick={handleClose}>Cancel</Button>
                    <Button variant="danger" onClick={this.handleDeleteAction}>Delete</Button>
                </div>
            </div>
        );
    }
}

export default DeleteDialogue;