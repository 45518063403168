import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

class Apology extends React.Component {

    element = () => {
        if (this.props.errors == null) {
            return <p>
                {this.props.message}
            </p>
        } else {
            return <ul>
                {this.props.errors.map((e) => <li>{e}</li>)}
            </ul>;
        }
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.dismissFunction} border>
                <Alert variant="danger" onClose={this.props.dismissFunction} dismissible >
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                </Alert>
                {this.element()}
            </Modal>
        );
    }
}

export default Apology;