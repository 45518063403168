import { connect } from 'react-redux';
import Header from '../components/Header';
import {logOutUser} from '../actions/userActions';
import {showResource} from '../actions/actions';
import {hideResource} from '../actions/actions';
import resourceService from '../services/resources';

const mapStateToProps = state => {
  return {
    loggedInUser: state.users.loggedInUser,
  } 
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logOutUser()),
    showHome: () => dispatch(hideResource()),    
    showSubscriptionsView: () => dispatch(showResource(resourceService.resources.subscriptions))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
