import { connect } from 'react-redux';
import AddAppointment from '../components/AddAppointment';
import { addAppointment } from '../actions/appointmentActions';
import { apologize } from '../actions/actions';
import { checkIfNewAppointmentIsAllowed } from '../services/validationService';

const mapStateToProps = state => {
  return {
    customers: state.customers.customers,
    selectedCustomer: state.customers.selectedCustomer
  }
}

const save = (dispatch, appointment, closure) => {
  checkIfNewAppointmentIsAllowed(appointment, false)
  .then(
    appointment => dispatch(addAppointment(appointment, closure)),
    error => dispatch(apologize({ message: error != null ? (error.message != null ? error.message : error) : "No valid subscription was found for appointment." }))
  );
}

const mapDispatchToProps = dispatch => {
  return {
    saveAppointment: (appointment, closure) => save(dispatch, appointment, closure),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAppointment)
