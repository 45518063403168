import React from 'react';
import Customer from './Customer';
import CustomerCard from './CustomerCard';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class Customers extends React.Component {

  constructor(props) {
    super(props); 
    this.state = {collapsed: false}
  }

  componentWillMount() {
    this.props.loadCustomers();
  }

  handleSearchChange = (e) => {
    e.preventDefault();
    let form = e.target;

    var searchString = form.value;

    const { filterCustomers } = this.props;
    filterCustomers(searchString);
  }
  
  changeCollapsedState = (e) => {
    e.preventDefault();
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  render() {
    return (
      <div className="mt-3">
        <CustomerCard selectedCustomer={this.props.selectedCustomer}
          subscriptions={this.props.subscriptions}
          appointments={this.props.appointments}
          clearSelectedCustomer={this.props.clearSelectedCustomer} />

        <Accordion className="mt-3" defaultActiveKey="0">
          <Card className="px-0" style={{ border: 'none' }}>
            <Accordion.Toggle as={Card.Header} eventKey="0" style={{ cursor: 'pointer' }} onClick={this.changeCollapsedState}>
            <div className="d-flex">
              Customers  
              <div className="ml-auto"  >
                  <FontAwesomeIcon icon={this.state.collapsed ? faChevronDown : faChevronUp} size="1x" color="grey" className="sqdl-icon" />
              </div>
            </div>              
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="px-0 pt-2">
                <Form inline>
                  <FormControl className="w-100" id="customerSearch" type="text" placeholder="Search" onChange={this.handleSearchChange} />
                </Form>

                <ListGroup className="mt-1">
                  {this.props.customers.map((customer, index) =>
                    <Customer key={index} customer={customer} selectCustomer={this.props.selectCustomer} />
                  )}
                </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    );
  }
}

export default Customers;