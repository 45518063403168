import React from 'react';
import Modal from 'react-bootstrap/Modal';

class MyModal extends React.Component {
    
    render() {

        var show = this.props.show;
        var handleClose = this.props.close;
        
        return (
            <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.props.content}</Modal.Body>
          </Modal>
        );
    }
}

export default MyModal;