import firebase from '../config/firebaseConfig';
import dateService from '../services/dateService';

const db = firebase.firestore();
const auth = firebase.auth();
// const functions = firebase.app().functions('europe-west1');

const firebaseSchedulerApi = {};

const getCompanyId = () => auth.currentUser.company.id

// const errorHandler = (error, handler) => {
//     console.log(error);
//     handler();
// }

const addCommonFields = (entity, create) => {
    if (auth.currentUser.company == null) {
        throw new Error("company is null!!!"); 
    }
    entity.ownerCompanyId = auth.currentUser.company.id;
    entity.ownerUserId = auth.currentUser.uid;
    if (create) {
        entity.createdOn = dateService.getCurrentTimeAsIsoString();
        entity.revision = -1;
    }
    entity.updatedOn = dateService.getCurrentTimeAsIsoString();
    entity.revision = entity.revision + 1;
}

const queryEntities = (query) => {
    return query.get()
    .then((querySnapshot) => {
        var entities = [];
        querySnapshot.forEach((doc) => {
            var entity = doc.data();
            entity.id = doc.id;
            entities.push(entity);
        });
        return entities;
    });
}

firebaseSchedulerApi.addCustomer = (customer) => {
    addCommonFields(customer, true);
    return db.collection('customers')
        .add(customer)
        .then(ref => {
            customer.id = ref.id;
            return customer;
        });
}

firebaseSchedulerApi.updateCustomer = (customer) => {
    addCommonFields(customer);
    return db.collection('customers')
        .doc(customer.id)
        .update(customer);
}

firebaseSchedulerApi.getCustomers = () => {
    var query = db.collection("customers")
        .where("ownerCompanyId", "==", getCompanyId());

    return queryEntities(query);
}

firebaseSchedulerApi.getSubscriptions = (customerId, date, includeAfter) => {
    var query = db.collection("subscriptions").where("ownerCompanyId", "==", getCompanyId());
    if (customerId != null) {
        query = query.where("customerId", "==", customerId)
    }
    if (date != null) {
        var filterBy = dateService.getDateString(date);
        if (includeAfter) {
            query = query.where("endDate", ">=", filterBy)
        } else {
            query = query.where("startDate", "<=", filterBy)
        }
    }
    return queryEntities(query);
}

firebaseSchedulerApi.addSubscription = (subscription) => {
    addCommonFields(subscription, true);
    return db.collection('subscriptions')
        .add(subscription)
        .then(ref => {
            subscription.id = ref.id;
            return subscription;
        });
}

firebaseSchedulerApi.updateSubscription = (subscription) => {
    addCommonFields(subscription);
    return db.collection('subscriptions')
        .doc(subscription.id)
        .update(subscription)
        ;
}

firebaseSchedulerApi.deleteSubscription = (subscriptionId) => {
    return db.collection('subscriptions')
        .doc(subscriptionId)
        .delete()
        ;
}

firebaseSchedulerApi.getAppointments = (customerId, date, includeAfter) => {
    var query = db.collection("appointments").where("ownerCompanyId", "==", getCompanyId());
    if (customerId != null) {
        query = query.where("customerId", "==", customerId)
    }
    if (date != null) {
        if (includeAfter) {
            query = query.where("endDate", ">=", dateService.getDateString(date))
        } else {
            query = query.where("startDate", "<=", dateService.getDateString(date))
        }
    }
    return queryEntities(query);
}

firebaseSchedulerApi.getAppointmentsBetween = (customerId, startDate, endDate) => {
    var query = db.collection("appointments").where("ownerCompanyId", "==", getCompanyId());
    if (customerId != null) {
        query = query.where("customerId", "==", customerId)
    }
    if (startDate != null) {
        query = query.where("startDate", "<=", dateService.getDateString(startDate))   
    }
    if (endDate != null) {
        query = query.where("endDate", "<=", dateService.getDateString(endDate))   
    }

    return queryEntities(query);
}

firebaseSchedulerApi.addAppointment = (appointment) => {
    addCommonFields(appointment, true);
    return db.collection('appointments')
        .add(appointment)
        .then(ref => {
            appointment.id = ref.id;
            return appointment;
        });
}

firebaseSchedulerApi.updateAppointment = (appointment) => {
    addCommonFields(appointment);
    return db.collection('appointments')
        .doc(appointment.id)
        .update(appointment);
}

firebaseSchedulerApi.deleteAppointment = (appointmentId) => {
    return db.collection('appointments')
        .doc(appointmentId)
        .delete();
}

firebaseSchedulerApi.login = (user) => {
    //at this point the user object that we get got to us as a result of a successful login. 
    //we will validate if there is a company associated to the user and if not we execute the error action
    return db.collection('companies')
        .where("users", "array-contains-any", [user.uid])
        .get()
        .then(querySnapshot => {
            var companies = [];
            querySnapshot.forEach(
                doc => {
                    var company = doc.data();
                    company.id = doc.id;
                    companies.push(company);
                }
            )
            if (companies.length === 1) {
                auth.currentUser.company = companies[0];//hack but it's easy 
                user.company = companies[0];
                return user;
            } else {
                //TODO this error message is not shown now; handle it later
                throw new Error("Cannot find a company the user is part of.");
            }
        });
}

firebaseSchedulerApi.logout = () => {
    return auth.signOut();
}

// firebaseSchedulerApi.callFunction = (functionName) => {
//     var f = functions.httpsCallable(functionName);
//     f()
//         .then(result => console.log("execution result", result))
//         .catch(e => console.log("error: ", e));
// }

export default firebaseSchedulerApi;