import { RESOURCES_ACTIONS } from '../actions/actions';

const getInitialState = () => ({
  resourceToShow: null,

});

const resources = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESOURCES_ACTIONS.SHOW_RESOURCE:
      return {
        resourceToShow: action.resource,
      }
    case RESOURCES_ACTIONS.HIDE_RESOURCE:
      return {
        resourceToShow: null,//for now we just hide everything
      }

    default:
      return state
  }
}

export default resources