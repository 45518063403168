import { CUSTOMER_ACTIONS, apologize } from './actions';
import schedulerApi from '../services/schedulerApi';


export function addCustomer(customer, closure) {
  return function (dispatch) {
    return schedulerApi.addCustomer(customer)
      .then(addedCustomer => {
        dispatch(customerAdded(addedCustomer));
        if (closure != null) {
          dispatch(() => closure())
        }
      },
      error => dispatch(apologize(error))
    )
  };
}

export function updateCustomer(customer, closure) {
  return function (dispatch) {
    return schedulerApi.updateCustomer(customer)
    .then(updated => {
        dispatch(customerUpdated(customer));
        if (closure != null) {
          dispatch(() => closure())
        }
      },
      error => dispatch(apologize(error))
    )
  };
}

export function loadCustomers() {
  return function (dispatch) {
    return schedulerApi.getCustomers()
    .then(
      customers => {
        customers.forEach(customer => dispatch(customerAdded(customer)));
        return Promise.resolve(customers);
      },
      error => dispatch(apologize(error))
    );
  };
}

export function selectCustomer(customer) {
  return {
    type: CUSTOMER_ACTIONS.CUSTOMER_SELECTED,
    customer: customer
  };
}

export function clearSelectedCustomer() {
  return {
    type: CUSTOMER_ACTIONS.CLEAR_SELECTED_CUSTOMER
  };
}

export function filterCustomers(string) {
  return {
    type: CUSTOMER_ACTIONS.FILTER_CUSTOMERS,
    customerFilterString: string
  };
}

function customerAdded(addedCustomer) {
  return {
    type: CUSTOMER_ACTIONS.CUSTOMER_ADDED,
    addedCustomer
  }
}

function customerUpdated(updatedCustomer) {
  return {
    type: CUSTOMER_ACTIONS.CUSTOMER_UPDATED,
    updatedCustomer
  }
}