import { connect } from 'react-redux';
import SubscriptionResource from '../components/SubscriptionResource'
import customerService from '../services/customerService';
import { deleteSubscription } from '../actions/subscriptionActions';
import dateService from '../services/dateService';

const getFilteredList = (subscriptions, selectedCustomer) => {
  if (selectedCustomer != null) {
    return subscriptions.filter(s => s.customerId === selectedCustomer.id)
  }
  return subscriptions;
}

const getSortedList = (subscriptions) => {
  return subscriptions.sort((a, b) => {
    const ordinal = a.customer.name.toLowerCase().localeCompare(b.customer.name.toLowerCase());
    if (ordinal === 0) {
      return a.startDate.localeCompare(b.startDate)
    }
    return ordinal;
  })
}

const markAsActive = (subscriptions) => {
  subscriptions.forEach(subscription => {
    if (dateService.isBetween(dateService.getCurrentTimeAsIsoString(), subscription.startDate, subscription.endDate, true)) {
      subscription.active = true;
    }
  });
  return subscriptions;
}

const mapStateToProps = state => {
  return {
    subscriptions: markAsActive(getFilteredList(
      getSortedList(customerService.enrichWithCustomers(state.subscriptions.subscriptions, state.customers.customers)),
      state.customers.selectedCustomer
    )),
    appointments: state.appointments.appointments
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteSubscription: (subscription, closure) => dispatch(deleteSubscription(subscription, closure)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionResource)
