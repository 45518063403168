export const ERRORS = {
  APOLOGIZE: 'APOLOGIZE',
  DISMISS_APOLOGY: 'DISMISS_APOLOGY'
}

export const CUSTOMER_ACTIONS = {
  CUSTOMER_ADDED: 'CUSTOMER_ADDED',
  CUSTOMER_UPDATED: 'CUSTOMER_UPDATED',
  CUSTOMER_SELECTED: 'CUSTOMER_SELECTED',
  CLEAR_SELECTED_CUSTOMER: 'CLEAR_SELECTED_CUSTOMER',
  FILTER_CUSTOMERS: 'FILTER_CUSTOMERS'
};

export const SUBSCRIPTION_ACTIONS = {
  SUBSCRIPTION_ADDED: 'SUBSCRIPTION_ADDED',
  SUBSCRIPTION_UPDATED: 'SUBSCRIPTION_UPDATED',
  SUBSCRIPTION_DELETED: 'SUBSCRIPTION_DELETED',
};

export const APPOINTMENT_ACTIONS = {
  APPOINTMENT_ADDED: 'APPOINTMENT_ADDED',
  APPOINTMENT_UPDATED: 'APPOINTMENT_UPDATED',
  APPOINTMENT_DELETED: 'APPOINTMENT_DELETED',
};

export const USER_ACTIONS = {
  USER_LOGGED_IN: 'USER_LOGGED_IN',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
};

export const RESOURCES_ACTIONS = {
  SHOW_RESOURCE: 'SHOW_RESOURCE',
  HIDE_RESOURCE: 'HIDE_RESOURCE'
};

export function apologize(error) {
  return {
    type: ERRORS.APOLOGIZE,
    error: error,
  };
}

export function dismissApology() {
  return {
    type: ERRORS.DISMISS_APOLOGY
  };
}

export function showResource(resource) {
  return {
    type: RESOURCES_ACTIONS.SHOW_RESOURCE,
    resource
  }
}
export function hideResource(resource) {
  return {
    type: RESOURCES_ACTIONS.HIDE_RESOURCE,
    resource
  }
}
