import { connect } from 'react-redux';
import EditCustomer from '../components/EditCustomer';
import {addCustomer} from '../actions/customerActions';

const mapStateToProps = state => {
  return {

  } 
}

const mapDispatchToProps = dispatch => {
  return {
    saveCustomer: (customer, closure) => dispatch(addCustomer(customer, closure))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCustomer)
