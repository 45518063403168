import React, { Component } from 'react';
import './App.css';
import TwoSplitPane from './components/TwoSplitPane';
import HeaderContainer from './containers/HeaderContainer';
import ApologyContainer from './containers/ApologyContainer';
import CustomersContainer from './containers/CustomersContainer';
import CalendarContainer from './containers/CalendarContainer';
import FirebaseAuthContainer from './containers/FirebaseAuthContainer';
import resourceService from './services/resources';
import SubscriptionResourceContainer from './containers/SubscriptionResourceContainer';

class App extends Component {

  getResourceOnTheRight = () => {
    switch (this.props.resourceToShow) {
      case resourceService.resources.subscriptions:
        return <SubscriptionResourceContainer />;
      default:
          return <CalendarContainer />;
    }
  }

  render() {
    if (this.props.loggedInUser != null) {
      return (
        <div className="App">

          <div>
            <ApologyContainer />
          </div>

          <div >
            <HeaderContainer />
          </div>
          <div>
            <TwoSplitPane left={<CustomersContainer />} right={this.getResourceOnTheRight()} />
          </div>
        </div>
      )
    } else {
      return (
        <div className="App">
          <div>
            <ApologyContainer />
          </div>
          <h1>Please log in</h1>
          <FirebaseAuthContainer />
        </div>
      )
    }
  }
}

export default App;
