import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
// import DatePicker from './DatePicker';

class EditCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      validated: false
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      this.setState({
        validated: true
      })
      return;
    }

    const customer = {};
    if (this.props.edited != null) {
      customer.id = this.props.edited.id;
    }
    customer.firstName = form.elements.formGroupFirstName.value;
    customer.lastName = form.elements.formGroupLastName.value;
    customer.email = form.elements.formGroupEmail.value;
    customer.phone = form.elements.formGroupPhone.value;
    customer.notes = form.elements.formGroupNotes.value;
    customer.sendCalendarInvites = form.elements.formGroupSendCalendarInvites.checked
    // customer.dateOfBirth = form.elements.formGroupDateOfBirth.value;

    this.props.saveCustomer(customer, this.props.closure);
  }

  render() {
    var handleClose = this.props.closure;
    return (
      <Form noValidate validated={this.state.validated} onSubmit={this.onSubmit} >
        <Form.Group controlId="formGroupFirstName">
          <Form.Label>First Name *</Form.Label>
          <Form.Control placeholder="First Name" required type="text" defaultValue={this.props.edited != null ? this.props.edited.firstName : ""} />
        </Form.Group>
        <Form.Group controlId="formGroupLastName">
          <Form.Label>Last Name *</Form.Label>
          <Form.Control placeholder="Last Name" required type="text" defaultValue={this.props.edited != null ? this.props.edited.lastName : ""} />
        </Form.Group>
        <Form.Group controlId="formGroupEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control placeholder="Email" type="email" defaultValue={this.props.edited != null ? this.props.edited.email : ""} />
        </Form.Group>
        <Form.Group controlId="formGroupPhone">
          <Form.Label>Phone</Form.Label>
          <Form.Control placeholder="Phone" type="number" defaultValue={this.props.edited != null ? this.props.edited.phone : ""} />
        </Form.Group>
        <Form.Group controlId="formGroupNotes">
          <Form.Label>Notes</Form.Label>
          <Form.Control as="textarea" rows="5" type="text" placeholder="Notes" defaultValue={this.props.edited != null ? this.props.edited.notes : ""} />
        </Form.Group>
        <Form.Group controlId="formGroupSendCalendarInvites">
          <Form.Label>Send Calendar Invites</Form.Label>
          <Form.Check type="checkbox" label="Send invites" defaultChecked={this.props.edited != null ? this.props.edited.sendCalendarInvites : false} />
        </Form.Group>
        {/* <Form.Group controlId="formGroupDateOfBirth">
          <Form.Label>Date of Birth *</Form.Label>
          <DatePicker customInput={<Form.Control required type="text" />} 
          startDate={this.props.edited != null && this.props.edited.dateOfBirth != null ? this.props.edited.dateOfBirth : new Date()} 
          defaultValue={this.props.edited != null ? this.props.edited.dateOfBirth : ""}/>
        </Form.Group> */}
        <div className="float-right">
          <Button className="mr-2" variant="light" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </div>
      </Form>
    );
  }
}

export default EditCustomer;