import { CUSTOMER_ACTIONS } from '../actions/actions';
import colorService from '../services/colorService';

const getInitialState = () => ({
  customers: []
});

const getCustomer = customer => {
  let c = customer;
  c.color = colorService.getColor(c.id).hex;
  c.name =  c.lastName + " " + c.firstName;
  return c;
}

const getUpdatedCustomersList = (customers, updatedCustomer) => {
  var updatedCustomerIndex = customers.findIndex(a => a.id === updatedCustomer.id);
  customers[updatedCustomerIndex].createdOn = updatedCustomer.createdOn;
  customers[updatedCustomerIndex].updatedOn = updatedCustomer.updatedOn;
  customers[updatedCustomerIndex].revision = updatedCustomer.revision;
  customers[updatedCustomerIndex].name = updatedCustomer.lastName + " " + updatedCustomer.firstName;
  customers[updatedCustomerIndex].firstName = updatedCustomer.firstName;
  customers[updatedCustomerIndex].lastName = updatedCustomer.lastName;
  customers[updatedCustomerIndex].email = updatedCustomer.email;
  customers[updatedCustomerIndex].phone = updatedCustomer.phone;
  customers[updatedCustomerIndex].notes = updatedCustomer.notes;
  customers[updatedCustomerIndex].sendCalendarInvites = updatedCustomer.sendCalendarInvites;
  // customers[updatedCustomerIndex].dateOfBirth = updatedCustomer.dateOfBirth;

  return customers;
}

const customers = (state = getInitialState(), action) => {
  switch (action.type) {
    case CUSTOMER_ACTIONS.CUSTOMER_ADDED:
      return {
        ...state,
        customers: [...state.customers, getCustomer(action.addedCustomer)]
      }
    case CUSTOMER_ACTIONS.CUSTOMER_UPDATED:
      return {
        ...state,
        customers: getUpdatedCustomersList(state.customers, action.updatedCustomer)
      }
    case CUSTOMER_ACTIONS.CUSTOMER_SELECTED:
      return {
        ...state,
        selectedCustomer: action.customer
      }
    case CUSTOMER_ACTIONS.CLEAR_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedCustomer: null
      }
    case CUSTOMER_ACTIONS.FILTER_CUSTOMERS:
      return {
        ...state,
        customerFilterString: action.customerFilterString
      }
    default:
      return state
  }
}

export default customers