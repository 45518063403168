import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from '../config/firebaseConfig';

class FirebaseAuth extends React.Component {
    render() {
        const login = this.props.login;

        // Configure FirebaseUI.
        const uiConfig = {
            
            signInOptions: [
                // List of OAuth providers supported.
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                //   firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                //   firebase.auth.TwitterAuthProvider.PROVIDER_ID,
                //   firebase.auth.GithubAuthProvider.PROVIDER_ID
            ],
            callbacks: {
                signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                    // User successfully signed in.
                    // Return type determines whether we continue the redirect automatically
                    // or whether we leave that to developer to handle.
                    var user = {};
                    user.uid = authResult.user.uid;
                    user.displayName = authResult.user.displayName;
                    user.email = authResult.user.email;
                    user.photoURL = authResult.user.photoURL;

                    login(user);
    
                    return false;
                },
                uiShown: function () {
                    // The widget is rendered.
                    // Hide the loader.
                    // document.getElementById('loader').style.display = 'none';
                },

            },
            signInFlow: 'popup',
            // Terms of service url.
            tosUrl: '<your-tos-url>',
            // Privacy policy url.
            privacyPolicyUrl: '<your-privacy-policy-url>'
        };
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        firebase.auth().onAuthStateChanged(function(userFromStorage) {
            if (userFromStorage) {
                var user = {};
                user.uid = userFromStorage.uid;
                user.displayName = userFromStorage.displayName;
                user.email = userFromStorage.email;
                user.photoURL = userFromStorage.photoURL;

                login(user);
            } 
          });
          
        return (
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        );
    }
}

export default FirebaseAuth;