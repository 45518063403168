import { ERRORS } from '../actions/actions';
import ErrorWithSource from '../errors/ErrorWithSource';
import MultiError from '../errors/MultiError';

const getInitialState = () => ({
  showApology: false,
  message: ''
});

const errors = (state = getInitialState(), action) => {
  switch (action.type) {
    case ERRORS.APOLOGIZE:
      if (action.error instanceof MultiError) {
        return {
          showApology: true,
          message: action.error.message,
          errors: action.error.errors
        }
      } else if (action.error instanceof ErrorWithSource) {
        return {
          showApology: true,
          message: action.error.message,
          source: action.error.source
        }
      } else {
        return {
          showApology: true,
          message: action.error.message
        }
      }

      
    case ERRORS.DISMISS_APOLOGY:
      return {
        showApology: false,
        message: ''
      }
    default:
      return state
  }
}

export default errors