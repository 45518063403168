import React from 'react';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from '../components/MyModal';
import EditSubscriptionContainer from '../containers/EditSubscriptionContainer';
import DeleteDialogue from './DeleteDialogue';
import Form from 'react-bootstrap/Form';
import dateService from '../services/dateService';

class SubscriptionResource extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedSubscription: null,
            showEditCustomer: false,
            showDeleteSubscription: false,
            showOnlyActive: false
        }
    }

    showEditSubscription = (e, subscription) => this.setState({ showEditSubscription: true, selectedSubscription: subscription })
    hideEditSubscription = () => this.setState({ showEditSubscription: false, selectedSubscription: null })

    showDeleteSubscription = (e, subscription) => this.setState({ showDeleteSubscription: true, selectedSubscription: subscription })
    hideDeleteSubscription = () => this.setState({ showDeleteSubscription: false, selectedSubscription: null })

    deleteSubscription = (argument) => {
        this.props.deleteSubscription(argument, () => {
            this.hideDeleteSubscription();
        })
    }

    getSubscriptions = () => {
        if (this.state.showOnlyActive) {
            return this.props.subscriptions.filter(s => s.active === true)
        }
        return this.props.subscriptions;
    }

    toggleShowOnlyActive = () => {
        const current = this.state.showOnlyActive;
        this.setState({ showOnlyActive: !current })
    }

    getUsedCount = (subscription) => {
        const now = dateService.getCurrentTimeAsIsoString();
        if (subscription.active) {
            return subscription.numberOfEntries - this.props.appointments
            .filter(a => a.customerId === subscription.customerId)
            .filter(a => dateService.isBetween(a.startDate, subscription.startDate, now, true))
            .length
        }
        return 0;
    }

    getPlannedCount = (subscription) => {

    }

    render() {
        return (
            <div className="mt-3">
                <Container className="ml-0 mr-0">
                    <Row>
                        <Col sm={8}>
                            <h4 className="text-left">Subscriptions</h4>
                        </Col>
                        <Col sm={4}>
                            <Form>
                                {/* <Form.Check inline type="checkbox" label="Show Only Active"  /> */}
                                <Form.Group controlId="filterActive">
                                    <Form.Check type="switch" size="lg" label="Show Only Active" onClick={this.toggleShowOnlyActive} />
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Customer</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Entries</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getSubscriptions().map((subscription, index) =>
                            <tr key={index}>
                                <td>
                                    <Container>
                                        <Row>
                                            <Col hidden={!subscription.active} md="auto">
                                                <FontAwesomeIcon icon={faStar} size="1x" color="lightblue" className="sqdl-icon" />
                                            </Col>
                                            <Col md="auto">{subscription.customer.name}</Col>
                                        </Row>
                                    </Container>
                                </td>
                                <td>{subscription.startDate}</td>
                                <td>{subscription.endDate}</td>
                                <td>{subscription.active ? (this.getUsedCount(subscription)  + " / " + subscription.numberOfEntries) : subscription.numberOfEntries}</td>
                                <td>
                                    <Container md="auto">
                                        <Row md="auto">
                                            <Col md="auto">
                                                <div className="ml-auto" style={{ cursor: 'pointer' }} onClick={(e) => this.showEditSubscription(e, subscription)} >
                                                    <FontAwesomeIcon icon={faEdit} size="1x" color="grey" className="sqdl-icon" />
                                                </div>
                                            </Col>
                                            <Col md="auto">
                                                <div className="ml-auto" style={{ cursor: 'pointer' }} onClick={(e) => this.showDeleteSubscription(e, subscription)} >
                                                    <FontAwesomeIcon icon={faTrashAlt} size="1x" color="red" className="sqdl-icon" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <Modal
                    content={<EditSubscriptionContainer
                        edited={this.state.selectedSubscription}
                        closure={this.hideEditSubscription} />
                    }
                    title="Edit Subscription" show={this.state.showEditSubscription} close={this.hideEditSubscription}
                />
                <Modal
                    content={<DeleteDialogue
                        id={this.state.selectedSubscription != null ? this.state.selectedSubscription.id : null}
                        close={this.hideDeleteSubscription}
                        title={"Subscription for: " + (this.state.selectedSubscription != null ? this.state.selectedSubscription.customer.name : null)}
                        toDelete={this.state.selectedSubscription != null ? this.state.selectedSubscription.id : null}
                        deleteAction={this.deleteSubscription} />
                    }
                    title="Delete subscription" show={this.state.showDeleteSubscription} close={this.hideDeleteSubscription}
                />

            </div>
        );
    }
}

export default SubscriptionResource;