import { USER_ACTIONS } from '../actions/actions';

const getInitialState = () => ({
});

const users = (state = getInitialState(), action) => {
  switch (action.type) {
    case USER_ACTIONS.USER_LOGGED_IN:
      return {
        loggedInUser: action.loggedInUser
      }
    case USER_ACTIONS.USER_LOGGED_OUT:
      return {
        loggedInUser: null
      }
    default:
      return state
  }
}

export default users