import React from 'react';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import EditCustomerContainer from '../containers/EditCustomerContainer';
import EditSubscriptionContainer from '../containers/EditSubscriptionContainer';
import Modal from '../components/MyModal';
import dateService from '../services/dateService';

class CustomerCard extends React.Component {

    constructor(props) {
        super(props);
        let activeSubscription = {};
        if (this.props.selectedCustomer != null) {
            activeSubscription = this.props.subscriptions.filter(s => s.customerId === this.props.selectedCustomer.id)[0];
        }
        this.state = {
            activeSubscription: activeSubscription,
            showEditCustomer: false
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (true) {
            let activeSubscription = {};
            if (props.selectedCustomer != null) {
                activeSubscription = props.subscriptions
                    .filter(s => s.customerId === props.selectedCustomer.id && s.startDate < new Date().toISOString() && s.endDate > new Date().toISOString())[0];
                if (activeSubscription != null) {
                    activeSubscription.numberOfEntriesLeft = activeSubscription.numberOfEntries - props.appointments
                        .filter(a => a.customerId === activeSubscription.customerId && a.startDate > activeSubscription.startDate && a.endDate < activeSubscription.endDate)
                        .length
                } 
            }
            return {
                activeSubscription: activeSubscription
            };
        }
        return null;
    }

    formatDate = (date) => {
        if (date != null) {
            return dateService.getDateString(date);
        }
        return null;
    }

    showEditCustomer = () => this.setState({ showEditCustomer: true })
    hideEditCustomer = () => this.setState({ showEditCustomer: false })

    showEditSubscription = () => this.setState({ showEditSubscription: true })
    hideEditSubscription = () => this.setState({ showEditSubscription: false })

    render() {

        return (
            <div>
                <Card hidden={this.props.selectedCustomer == null} bg="light" className="mt-1 mb-1"  >
                    <Card.Header>
                        <div className="d-flex">
                            <div>
                                <FontAwesomeIcon icon={faSquare} color={this.props.selectedCustomer && this.props.selectedCustomer.color} />
                                <FontAwesomeIcon className="mr-1" icon={faSquare} color={this.props.selectedCustomer && this.props.selectedCustomer.color} />
                                {this.props.selectedCustomer && this.props.selectedCustomer.name}
                            </div>
                            <div className="ml-auto" style={{ cursor: 'pointer' }} onClick={this.showEditCustomer} >
                                <FontAwesomeIcon icon={faEdit} size="1x" color="grey" className="sqdl-icon" />
                            </div>
                            <div className="ml-auto" style={{ cursor: 'pointer' }} onClick={this.props.clearSelectedCustomer} >
                                <FontAwesomeIcon icon={faWindowClose} size="1x" color="grey" className="sqdl-icon" />
                            </div>
                        </div>
                    </Card.Header>

                    <Card.Body>
                        <div className="text-left" hidden={this.state.activeSubscription == null}>
                            <div className="d-flex">
                                Active subscription
                                <div className="ml-auto" style={{ cursor: 'pointer' }} onClick={this.showEditSubscription} >
                                    <FontAwesomeIcon icon={faEdit} size="1x" color="grey" className="sqdl-icon" />
                                </div>
                            </div>
                            <div><i>Start: </i> {this.state.activeSubscription && this.formatDate(this.state.activeSubscription.startDate)}</div>
                            <div><i>End: </i> {this.state.activeSubscription && this.formatDate(this.state.activeSubscription.endDate)}</div>
                            <div><i>Entries: </i> {this.state.activeSubscription && this.state.activeSubscription.numberOfEntries}</div>
                            <div><i>Remaining: </i> {this.state.activeSubscription && this.state.activeSubscription.numberOfEntriesLeft}</div>
                        </div>

                    </Card.Body>
                </Card>
                <Modal content={<EditCustomerContainer edited={this.props.selectedCustomer} closure={this.hideEditCustomer} />} title="Edit Customer" show={this.state.showEditCustomer} close={this.hideEditCustomer} />
                <Modal content={<EditSubscriptionContainer edited={this.state.activeSubscription} closure={this.hideEditSubscription} />} title="Edit Subscription" show={this.state.showEditSubscription} close={this.hideEditSubscription} />
            </div>
        )
    }
}
export default CustomerCard;