import React from 'react';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Modal from './MyModal';
import AddSubscriptionContainer from '../containers/AddSubscriptionContainer';
import AddCustomerContainer from '../containers/AddCustomerContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

// !!! hack to execute scripts... it works for now
// import firebaseSchedulerApi from '../services/firebaseSchedulerApi';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginShown: false,
      showAddCustomer: false
    }
  }

  // executeFunction = () => { firebaseSchedulerApi.callFunction('cleanCustomerNames') }

  showAddCustomer = () => { this.setState({ showAddCustomer: true }) }
  hideAddCustomer = () => { this.setState({ showAddCustomer: false }) }

  showAddSubscription = () => { this.setState({ showAddSubscription: true }) }
  hideAddSubscription = () => { this.setState({ showAddSubscription: false }) }

  render() {
    return (

      <Navbar bg="dark" expand="lg">
        <Navbar.Brand href="#home" onClick={this.props.showHome}>
          {/* <Image className="mt-0 p-0 border-0" style={{height: "40px"}} src="sqdl_logo.png" alt="sqdl logo" thumbnail /> */}
          <font color="white">{this.props.loggedInUser.company.name}</font>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {/* <Nav.Link href="#home">Home</Nav.Link> */}
          </Nav>

          <div>
            <Button onClick={this.showAddSubscription} variant="success" className="pl-3">Add Subscription</Button>
            <Modal content={<AddSubscriptionContainer closure={this.hideAddSubscription} />} title="Add Subscription" show={this.state.showAddSubscription} close={this.hideAddSubscription} />
          </div>
          <div className="pl-3">
            <Button onClick={this.showAddCustomer} variant="success" className="pl-3">Add Customer</Button>
            <Modal content={<AddCustomerContainer closure={this.hideAddCustomer} />} title="Add Customer" show={this.state.showAddCustomer} close={this.hideAddCustomer} />
          </div>

          <div className="pl-3">
            <DropdownButton alignRight id="dropdown-profile" variant="secondary" title="Resources">
              <Dropdown.Item onClick={this.props.showSubscriptionsView}>Subscriptions</Dropdown.Item>
            </DropdownButton>
          </div>

          <div className="pl-3">
            <DropdownButton alignRight id="dropdown-profile" variant="secondary" title={<FontAwesomeIcon icon={faUserCircle} size="1x" />}>
              <Dropdown.Header>{this.props.loggedInUser.displayName}</Dropdown.Header>
              <Dropdown.Item onClick={this.props.logout}>Logout</Dropdown.Item>
            </DropdownButton>
          </div>



          {/* COMMENT for anything that is not local! hack to execute scripts! */}
          {/* <div className="pl-2">
            <Button onClick={this.executeFunction}>Execute function</Button>
          </div> */}
          {/* END COMMENT */}

        </Navbar.Collapse>
      </Navbar>


    );
  }
}

export default Header;