import { APPOINTMENT_ACTIONS } from '../actions/actions';

const getInitialState = () => ({
  appointments: []
});

const getUpdatedAppointmentList = (appointments, updatedAppointment) => {
  var updatedAppointmentIndex = appointments.findIndex(a => a.id === updatedAppointment.id);
  appointments[updatedAppointmentIndex].createdOn = updatedAppointment.createdOn;
  appointments[updatedAppointmentIndex].updatedOn = updatedAppointment.updatedOn;
  appointments[updatedAppointmentIndex].revision = updatedAppointment.revision;
  appointments[updatedAppointmentIndex].startDate = updatedAppointment.startDate;
  appointments[updatedAppointmentIndex].endDate = updatedAppointment.endDate;
  
  return appointments;
}

const customers = (state = getInitialState(), action) => {
  switch (action.type) {
    case APPOINTMENT_ACTIONS.APPOINTMENT_ADDED:
      return {
        ...state,
        appointments: [...state.appointments, action.addedAppointment]
      }
    case APPOINTMENT_ACTIONS.APPOINTMENT_UPDATED:
      return {
        ...state,
        appointments: getUpdatedAppointmentList(state.appointments, action.updatedAppointment)
      }
    case APPOINTMENT_ACTIONS.APPOINTMENT_DELETED:
      return {
        ...state,
        appointments: state.appointments.filter(a => a.id !== action.deletedAppointment)
      }
    default:
      return state
  }
}

export default customers