import React from "react";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

//examples https://reactdatepicker.com/#

class DatePicker extends React.Component {

    constructor(props) {
        super(props);
        if (this.props.startDate == null) {
            this.state ={
                date: new Date()
            };
        } else {
            var startDate = this.props.startDate;
            if (typeof "foo" === "string") {
                startDate = new Date(startDate)
            }
            this.state = {
                date: startDate
            };
        }
    }

    handleChange = date => {
        this.setState({
            date: date
        });
    };

    render() {
        return (
            <div className="customDatePickerWidth">
                <ReactDatePicker
                    dateFormat={this.props.dateFormat ? this.props.dateFormat : "yyyy-MM-dd"}
                    selected={this.state.date}
                    onChange={this.handleChange}
                    customInput={this.props.customInput}
                />
            </div>
        );
    }
}

export default DatePicker;