import firebaseSchedulerApi from './firebaseSchedulerApi';
import dateService from '../services/dateService';
import ErrorWithSource from '../errors/ErrorWithSource'

//checks if a valid subscription can be found and there are remaining entries
export function checkIfNewAppointmentIsAllowed(appointment, checkForEmptySlot) {
    return firebaseSchedulerApi.getSubscriptions(
        appointment.customerId,
        appointment.startDate,
        true)
        .then(subscriptions => {
            subscriptions = subscriptions.filter(s => s.startDate < appointment.startDate && s.endDate > appointment.endDate)
            if (subscriptions.length !== 1) {
                throw new ErrorWithSource(appointment, "No valid subscription found for customer.");
            }
            var activeSubscription = subscriptions[0];
            return Promise.all([
                Promise.resolve(activeSubscription),
                firebaseSchedulerApi.getAppointments(
                    appointment.customerId,
                    activeSubscription.startDate,
                    true)]);
        })
        .then(results => {
            var activeSubscription = results[0];
            var appointments = results[1];
            if (checkForEmptySlot) {
                if (appointments.filter(a => appointment.startDate === a.startDate && appointment.endDate === a.endDate)[0] != null) {
                    throw new ErrorWithSource(appointment, "Slot is not empty for customer");
                }
            }
            if (appointments.length < activeSubscription.numberOfEntries) {
                return appointment;
            } else {
                throw new ErrorWithSource(appointment, "No entries remaining for subscription.");
            }
        });
}

//checks for overlaps and correctness
export function addUpdateSubscriptionIfAllowed(subscription, success, failure) {
    if (subscription.endDate <= subscription.startDate) {
        return () => failure("End date must be after start date");
    }

    return firebaseSchedulerApi.getSubscriptions(
        subscription.customerId,
        null,
        null
    ).then(
        subscriptions => {
            subscriptions = subscriptions.filter(s => (
                s.id !== subscription.id &&
                (
                    dateService.isBetween(subscription.startDate, s.startDate, s.endDate, true)
                    || dateService.isBetween(subscription.endDate, s.startDate, s.endDate, true)
                    || (dateService.isBefore(s.startDate, subscription.startDate) && dateService.isBefore(subscription.endDate, s.endDate))
                    || (dateService.isBefore(subscription.startDate, s.startDate) && dateService.isBefore(s.endDate, subscription.endDate))
                )
            ))
            if (subscriptions.length > 0) {
                failure("Subscription is overlapping with another existing subscription.");
                return;
            } else {
                success()
            }
        },
        failure
    )

}