import { USER_ACTIONS, apologize } from './actions';
import schedulerApi from '../services/schedulerApi';

export function logInUser(user) {
    return function (dispatch) {
        return schedulerApi.login(user)
        .then(
            loggedInUser => dispatch(userLoggedIn(loggedInUser)),
            () => dispatch(logOutUser())
        );
    };
}

function userLoggedIn(loggedInUser) {
    return {
        type: USER_ACTIONS.USER_LOGGED_IN,
        loggedInUser
    }
}

export function logOutUser() {
    return function (dispatch) {
        return schedulerApi.logout()
        .then(
            () => dispatch(userLoggedOut()),
            (error) => apologize(error.message)
        )
    }
}

function userLoggedOut(loggedInUser) {
    return {
        type: USER_ACTIONS.USER_LOGGED_OUT
    }
}