import { connect } from 'react-redux';
import Apology from '../components/Apology';
import {dismissApology} from '../actions/actions';

const mapStateToProps = state => ({
  show: state.errors.showApology,
  message: state.errors.message,
  errors: state.errors.errors
})

const mapDispatchToProps = dispatch => {
  return {
    dismissFunction: () => dispatch(dismissApology())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Apology)
