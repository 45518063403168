import { combineReducers } from 'redux';
import errors from './errors';
import customers from './customers';
import subscriptions from './subscriptions';
import appointments from './appointments';
import users from './users';
import resources from './resources';

export default combineReducers({
  errors,
  customers,
  subscriptions,
  appointments,
  users,
  resources
})