import { connect } from 'react-redux';
import EditSubscription from '../components/EditSubscription';
import {addSubscription} from '../actions/subscriptionActions';
import { apologize } from '../actions/actions';
import { addUpdateSubscriptionIfAllowed as executeActionIfAddEditSubscriptionIsAllowed } from '../services/validationService';

const mapStateToProps = state => {
  return {
    customers: state.customers.customers,
    selectedCustomer: state.customers.selectedCustomer
  } 
}

const save = (dispatch, subscription, closure) => {
  executeActionIfAddEditSubscriptionIsAllowed(subscription,
    () => dispatch(addSubscription(subscription, closure)),
    (message) => dispatch(apologize({ message: message != null ? message : "Invalid subscription." }))
  );
}

const mapDispatchToProps = dispatch => {
  return {
    saveSubscription: (subscription, closure) => save(dispatch, subscription, closure)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSubscription)
