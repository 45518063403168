import { connect } from 'react-redux';
import App from './App';

const mapStateToProps = state => {
  return {
    loggedInUser: state.users.loggedInUser,
    resourceToShow: state.resources.resourceToShow
  } 
}

const mapDispatchToProps = dispatch => {
  return {
    
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
