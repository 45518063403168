import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import DatePicker from './DatePicker';

class EditSubscription extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSelectCustomer = this.onSelectCustomer.bind(this);
        const customer = this.props.selectedCustomer != null ? this.props.selectedCustomer : (this.props.edited != null ? this.props.edited.customer : null);
        this.state = {
            selectedCustomer: customer,
            dropdownTitle: customer != null ? customer.name : "Select Customer"
        };

    }

    onSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            this.setState({
                validated: true
            })
            return;
        }

        const subscription = {};
        if (this.props.edited != null) {
            subscription.id = this.props.edited.id;
        }
        subscription.customerId = form.elements.formGroupCustomerId.value;
        subscription.numberOfEntries = form.elements.formGroupNumberOfEntries.value;
        subscription.startDate = form.elements.formGroupStartDate.value;
        subscription.endDate = form.elements.formGroupEndDate.value;
        subscription.price = form.elements.formGroupPrice.value;
        subscription.notes = form.elements.formGroupNotes.value;

        this.props.saveSubscription(subscription, this.props.closure);
    }

    onSelectCustomer = (key, event) => {
        var selected = this.props.customers.find(customer => customer.id === key);
        this.setState(
            {
                selectedCustomer: selected,
                dropdownTitle: selected.name
            }
        );
    }

    render() {
        var handleClose = this.props.closure;
        var startDate = new Date();
        var endDate = new Date().setDate(startDate.getDate()+5*7);
        return (
            <Form noValidate validated={this.state.validated} onSubmit={this.onSubmit} >
                <Form.Group controlId="formGroupCustomerId">
                    <Form.Label>Customer *</Form.Label>
                    <DropdownButton title={this.state.dropdownTitle} disabled={this.props.edited != null}>
                        {this.props.customers.map((customer, index) =>
                            <Dropdown.Item key={index} eventKey={customer.id} onSelect={this.onSelectCustomer}>{customer.name}</Dropdown.Item>
                        )}
                    </DropdownButton>
                    <Form.Control required type="text" hidden={true} readOnly={this.state.selectedCustomer != null} defaultValue={this.state.selectedCustomer != null ? this.state.selectedCustomer.id : ""} /> 
                    <Form.Control.Feedback type="invalid">Please select customer</Form.Control.Feedback> 
                </Form.Group>
                <Form.Group controlId="formGroupNumberOfEntries">
                    <Form.Label>Number of entries *</Form.Label>
                    <Form.Control required type="number" placeholder="Number of entries" defaultValue={this.props.edited != null ? this.props.edited.numberOfEntries : ""} />
                </Form.Group>
                <Form.Group controlId="formGroupStartDate">
                    <Form.Label>Start Date *</Form.Label>
                    <DatePicker customInput={<Form.Control type="text" />} 
                    startDate={this.props.edited != null && this.props.edited.startDate != null ? this.props.edited.startDate : startDate} />
                </Form.Group>
                <Form.Group controlId="formGroupEndDate">
                    <Form.Label>End Date *</Form.Label>
                    <DatePicker customInput={<Form.Control type="text" />} 
                    startDate={this.props.edited != null && this.props.edited.endDate != null ? this.props.edited.endDate : endDate} />
                </Form.Group>
                <Form.Group controlId="formGroupPrice">
                    <Form.Label>Price</Form.Label>
                    <Form.Control type="number" placeholder="Price" defaultValue={this.props.edited != null ? this.props.edited.price : ""} />
                </Form.Group>
                <Form.Group controlId="formGroupNotes">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control as="textarea" rows="3" type="text" placeholder="Notes" defaultValue={this.props.edited != null ? this.props.edited.notes : ""} />
                </Form.Group>
                <div className="float-right">
                    <Button className="mr-2" variant="light" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Save
                    </Button>
                </div>
            </Form>
        );
    }
}

export default EditSubscription;
