import { connect } from 'react-redux';
import FirebaseAuth from '../components/FirebaseAuth';
import {logInUser} from '../actions/userActions';

const mapStateToProps = state => {
  return {
    
  } 
}

const mapDispatchToProps = dispatch => {
  return {
    login: user => dispatch(logInUser(user))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FirebaseAuth)
