const customerService = {};


customerService.enrichWithCustomers = (entities, customers) => {
    if (entities == null || customers == null) {
      return [];
    }
    let result = [];
    entities.forEach(element => {
      let s = element;
      s.customer = customers.filter(c => c.id === s.customerId)[0];
      result.push(s);
    });
    return result;
  }

export default customerService;