import { SUBSCRIPTION_ACTIONS } from '../actions/actions';

const getInitialState = () => ({
  subscriptions: []
});

const getUpdatedSubscriptionsList = (subscriptions, updatedSubscription) => {
  var updatedSubscriptionIndex = subscriptions.findIndex(a => a.id === updatedSubscription.id);
  subscriptions[updatedSubscriptionIndex].createdOn = updatedSubscription.createdOn;
  subscriptions[updatedSubscriptionIndex].updatedOn = updatedSubscription.updatedOn;
  subscriptions[updatedSubscriptionIndex].revision = updatedSubscription.revision;
  subscriptions[updatedSubscriptionIndex].numberOfEntries = updatedSubscription.numberOfEntries;
  subscriptions[updatedSubscriptionIndex].startDate = updatedSubscription.startDate;
  subscriptions[updatedSubscriptionIndex].endDate = updatedSubscription.endDate;
  subscriptions[updatedSubscriptionIndex].price = updatedSubscription.price;
  subscriptions[updatedSubscriptionIndex].notes = updatedSubscription.notes;

  return subscriptions;
}

const subscriptions = (state = getInitialState(), action) => {
  switch (action.type) {
    case SUBSCRIPTION_ACTIONS.SUBSCRIPTION_ADDED:
      return {
        ...state,
        subscriptions: [...state.subscriptions, action.addedSubscription]
      }
    case SUBSCRIPTION_ACTIONS.SUBSCRIPTION_UPDATED:
      return {
        ...state,
        subscriptions: getUpdatedSubscriptionsList(state.subscriptions, action.updatedSubscription)
      }
    case SUBSCRIPTION_ACTIONS.SUBSCRIPTION_DELETED:
      return {
        ...state,
        subscriptions: state.subscriptions.filter(s => s.id !== action.deletedSubscription)
      }
    default:
      return state
  }
}

export default subscriptions